body {
    padding-bottom: 20px;
    background-color: white !important;
    overflow-x: hidden;

    // ignore the text transform for placeholder text for all devices/browsers:
    input::-webkit-input-placeholder {
        /* WebKit browsers */
        text-transform: none;
    }
    input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        text-transform: none;
    }
    input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        text-transform: none;
    }
    input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        text-transform: none;
    }
    input::placeholder {
        /* Recent browsers */
        text-transform: none;
    }
}
