.declarationList {
    // list-style: decimal;
    list-style: none;
    counter-reset: list-number;

    > li {
        text-align: middle;
        padding-bottom: 1rem;
        > label {
            display: contents;
            margin-left: 24px;
        }
    }

    > li:before {
        counter-increment: list-number;
        content: '(' counter(list-number) ') ';
        padding-right: 1rem;
    }

    // > li::marker {
    //    content: '(' counter(li) ') ';
    // }
}
