.addressOrgWrapper {
    padding-bottom: 0.25rem;
}

.addressOrgDetailHeader {
    padding-bottom: 0.25rem;
}

.addressOrgDetailBody {
    padding-bottom: 0.25rem;
}

.googleWrapper {
    padding-top: 0.25rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.manualAddressWrapper {
    padding-top: 0.25rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.guidanceTextBody {
    padding-bottom: 0.5rem;
}
