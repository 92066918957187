/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__heading {
    position: relative; /* need to inject this so all our position:absolute work as expected */
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font-size: 20px;
    font-weight: 500;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    /* margin-right: 12px;
    transform: rotate(-45deg); */
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    /* migrated from another: */
    position: absolute;
    right: 2rem;
    top: 2rem;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: -webkit-transform 375ms ease-in-out;
    transition: transform 375ms ease-in-out;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
