.container {
    display: flex;
}

.fixedLeftColumn {
    min-width: 3.4rem;
}

.flexRightColumn {
    flex-grow: 1;
}
