.footer {
    position: relative;
    z-index: 1;
    /*bottom: 0;*/
    /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    box-shadow: 10px -3px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff !important;
}

.footer .nav-link {
    padding-top: 0;
    padding-bottom: 0;
}
