.pageContainerContent {
    min-height: 28rem;
}

.pageContainerContentHalfHeight {
    min-height: 14rem;
}

.pageFormContent {
    max-width: 99% !important; /* keeps the content tidy within boundary */
    min-height: 28rem;
}

.pageFormContentHalfHeight {
    max-width: 99% !important; /* keeps the content tidy within boundary */
    min-height: 14rem;
}

.pageFieldsContent {
    min-height: 28rem;
}

.pageFieldsContentHalfHeight {
    min-height: 14rem;
}
